import React from "react"
import Img from "gatsby-image"
import { FaFacebookF, FaInstagram, FaEnvelope } from "react-icons/fa"
import PropTypes from "prop-types"

// Styles
import styles from "../styles/components/trainer.module.scss"

const Trainer = ({ image, name, fb, insta }) => (
  <div className={styles.trainer}>
    <div className={styles.image}>
      <Img fluid={image} alt="" style={{ width: "auto", height: "500px" }} />
    </div>
    <div className={styles.info}>
      <p className={styles.name}>{name}</p>

      <a
        href="mailto:info@lifetimefitness.lk?Subject=Personal%20Training"
        target="_top"
      >
        <FaEnvelope />
      </a>
      <a href={fb} target="blank">
        <FaFacebookF />
      </a>
      <a href={insta} target="blank">
        <FaInstagram />
      </a>
    </div>
  </div>
)

export default Trainer

Trainer.defaultProps = {
  fb: `www.facebook.com`,
  insta: `www.instagram.com`,
}

Trainer.propTypes = {
  fb: PropTypes.string,
  insta: PropTypes.string,
}
