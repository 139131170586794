import React from "react"
import PropTypes from "prop-types"
import GoogleMapReact from "google-map-react"
import { graphql, useStaticQuery } from "gatsby"
import mapStyles from "../utils/mapStyles.json"

// Styles
import styles from '../styles/components/googleMap.module.scss'

const createMapOptions = () => {
  return {
    panControl: true,
    mapTypeControl: false,
    zoomControl: false,
    scrollwheel: false,
    styles: mapStyles,
  }
}
const GoogleMap = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          googleMapsApiKey
        }
      }
    }
  `)
  return (
    // Important! Always set the container height explicitly
    <div className={styles.map}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: data.site.siteMetadata.googleMapsApiKey }}
        defaultCenter={props.center}
        defaultZoom={props.zoom}
        options={createMapOptions}
      ></GoogleMapReact>
    </div>
  )
}

GoogleMap.defaultProps = {
  center: {
    lat: 6.877272,
    lng: 79.935,
  },
  zoom: 17,
}

GoogleMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
}

export default GoogleMap
