import React from "react"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Landing from "../components/landing"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import About from "../components/about"
import Header from "../components/header"
import Services from "../components/services"
import Team from "../components/team"
import Membership from "../components/membership"
import Gallery from "../components/gallery"
import Contact from "../components/contact"
import Footer from "../components/footer"
import Separator from "../components/separator"
import Reviews from "../components/reviews"
import Transformations from "../components/transformations"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "landing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1650, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tabletImage: file(relativePath: { eq: "landing@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1650, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobileImage: file(relativePath: { eq: "landing@1x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1650, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about: file(relativePath: { eq: "lifetime-fitness-about.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oshan: file(relativePath: { eq: "oshan-abeykoon-lifetime-fitness.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const sources = [
    {
      ...data.mobileImage.childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
    {
      ...data.tabletImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 1366px)`,
    },
  ]

  return (
    <>
      <Header />
      <SEO
        title="Lifetime Fitness"
        description="Lifetime Fitness is more than just a gym - it's a caring family that's there to help you achieve your goals. Join our gym family today!"
      />
      <Landing sources={sources} />
      <About image={data.about.childImageSharp.fluid} />
      <Separator grey={true} />
      <Services />
      <Separator />
      <Team />
      <Separator />
      <Membership />
      <Separator />
      <Gallery />
      <Separator />
      <Reviews linkTo="/reviews" />
      <Separator />
      <Transformations />
      <Separator />
      <Contact />
      <Footer />
    </>
  )
}

export default IndexPage
