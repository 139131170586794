import React from "react"
import Title from "./title"
import ContactForm from "./contactForm"
import GoogleMap from "./googleMap"
import ContactInfo from "./contactInfo"
// Styles
import styles from "../styles/components/contact.module.scss"

const Contact = () => (
  <div className={styles.main} id="contact">
    <div className={styles.title}>
      <Title text="Contact Us" />
    </div>
    <div className={styles.grid}>
      <div className={styles.form}>
        <p>
          Send us a message and we'll get back to you as soon as possible!
        </p>
        <ContactForm />
      </div>
      <div className={styles.map}>
        <div className={styles.mobileTitle}>
          <Title text="Find Us" />
        </div>
        <div className={styles.mobileInfo}>
          <ContactInfo />
        </div>
        <GoogleMap />
        <div className={styles.infoGrid}>
          <ContactInfo />
        </div>
      </div>
    </div>
  </div>
)

export default Contact
