import React, { useState } from "react"
import Title from "./title"
import Rating from "./rating"
import { reviews } from "../utils/reviews.js"
import Modal from "./modal"

// Styles
import styles from "../styles/components/reviews.module.scss"

const minReviews = 6
const maxReviews = 24

const Reviews = () => {
  const [count, setCount] = useState(minReviews)

  const runSetReviews = () => {
    if (count >= maxReviews) {
      setCount(minReviews)
    } else {
      setCount(count + minReviews)
    }
  }

  return (
    <div className={styles.main} id="pricing">
      <Title text="Customer Reviews" />
      <div className={styles.grid}>
        {reviews.slice(0, count).map((review, idx) => (
          <div key={idx}>
            <h4>{review.name}</h4>
            <Rating rating={review.rating} />
            <p>{review.review}</p>
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <Modal />
        <button className={styles.btn} onClick={() => runSetReviews()}>
          {count < maxReviews ? `Show More Reviews` : `Show Less`}
        </button>
      </div>
    </div>
  )
}

export default Reviews
