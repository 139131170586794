import React from "react"
import Title from "./title"
import Service from "./service"

// Images
import icon1 from "../images/lifetime-fitness-training-icon.svg"
import icon2 from "../images/lifetime-fitness-fitness-check-icon.svg"
import icon3 from "../images/lifetime-fitness-workout-diet-icon.svg"

// Styles
import styles from "../styles/components/services.module.scss"

export default () => (
  <div className={styles.main} id="services">
    <div className={styles.title}>
      <Title text="Services" />
    </div>
    <div className={styles.grid}>
      <div className={styles.services}>
        <Service
          icon={icon1}
          title="ONE TO ONE TRAINING"
          desc="All our personal trainers are certified and ready to help with whatever fitness goal you may have!"
        />
        <Service
          icon={icon2}
          title="FITNESS CHECK-UP"
          desc="Get your Body Mass Index and Fat Percentage checked every month to keep track of your body composition."
        />
        <Service
          icon={icon3}
          title="JUICE &amp; SUPPLEMENT BAR"
          desc="We have a supplement bar at the gym with pre-workout, whey protein, BCAA, Greek Yoghurt and more!"
          last={true}
        />
      </div>
      <div className={styles.image}>
        {/* <ReactPlayer
          url="https://www.facebook.com/422919781093919/videos/1151668531649482/"
          playing={false}
          controls
          className="modal__player"
          onError={e => console.log(e)}
        /> */}
        <iframe
          height="100%"
          width="100%"
          allowFullScreen={true}
          title="360image"
          src="https://momento360.com/e/u/1692e74b052a447896e14e83a3b5809e?utm_campaign=embed&utm_source=other&utm_medium=other&heading=0&pitch=0&field-of-view=75"
        ></iframe>
      </div>
    </div>
  </div>
)
