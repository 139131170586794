import React from "react"

// Styles
import styles from "../styles/components/priceTable.module.scss"

const PriceTable = ({ info }) => (
  <div className={styles.item}>
    <img src={info.icon} alt="icon" />
    <h3 className={styles.title}>
      {info.type} <br></br>
      <span>Membership</span>
    </h3>
    <table className={styles.table}>
      <tbody>
        {info.prices.map((info, i) => (
          <tr key={i}>
            <td className={styles.desc}>{info.desc}</td>
            <td className={styles.price}>{info.price}</td>
          </tr>
        ))}
      </tbody>
    </table>

    <div className={styles.duration}>
      <p>Time Duration:</p>
      <p className={styles.time}>{info.time}</p>
    </div>
  </div>
)



export default PriceTable
