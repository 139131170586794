import React from "react"
import Title from "./title"
import PriceTable from "./priceTable"

// Styles
import styles from "../styles/components/membership.module.scss"

// Images
import platinumIcon from "../images/lifetime-fitness-platinum-membership-icon.svg"
import goldIcon from "../images/lifetime-fitness-gold-membership-icon.svg"
import silverIcon from "../images/lifetime-fitness-silver-membership-icon.svg"
import bronzeIcon from "../images/lifetime-fitness-bronze-membership-icon.svg"

const priceDetails = [
  {
    icon: platinumIcon,
    type: "Platinum",
    time: "4:00am to 12:00 Midnight",
    prices: [
      {
        desc: "Gents - Annual",
        price: "Rs. 65,000",
      },
      {
        desc: "Ladies - Annual",
        price: "Rs. 55,000",
      },
      {
        desc: "Couple - Annual",
        price: "Rs. 85,000",
      },
    ],
  },
  {
    icon: goldIcon,
    type: "Gold",
    time: "4:00am to 4:30pm",
    prices: [
      {
        desc: "Gents - Annual",
        price: "Rs. 48,000",
      },
      {
        desc: "Ladies - Annual",
        price: "Rs. 48,000",
      },
    ],
  },
  {
    icon: silverIcon,
    type: "Silver",
    time: "4:00am to 12:00 Midnight",
    prices: [
      {
        desc: "Individual - 6 Months",
        price: "Rs. 45,000",
      },
      {
        desc: "Individual - 3 Months",
        price: "Rs. 35,000",
      },
      {
        desc: "Individual - 1 Month",
        price: "Rs. 15,000",
      },
    ],
  },
]

const Membership = () => {
  return (
    <div className={styles.main} id="pricing">
      <Title text="Member Pricing" />
      <div className={styles.grid}>
        <PriceTable info={priceDetails[0]} />
        <PriceTable info={priceDetails[1]} />
        <PriceTable info={priceDetails[2]} />
      </div>
      <div className={styles.info}>
        {/* <img src={bronzeIcon} alt="icon"></img> */}
        {/* <p>
          Student - 6 Months <span>(10am - 4pm)</span>: Rs. 25,000{" "}
          <span className={styles.span}>|</span> Day Pass: Rs. 1,500
        </p> */}
        <p>Day Pass: Rs. 1,500</p>
      </div>
      <div className={styles.infoMobile}>
        <a href="tel:+94777775576">
          <button className={styles.btn}>Call Now</button>
        </a>
      </div>
    </div>
  )
}

export default Membership
