import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
// Styles
import styles from "../styles/components/landing.module.scss"
import {
  FaInstagram,
  FaFacebookF,
  FaFacebook,
  FaEnvelope,
  FaPhone,
} from "react-icons/fa"
import { HeaderCarousel } from "./carousel"

const Landing = ({ sources }) => (
  <div style={{ height: "100vh", position: "relative" }} id="home">
    {/* Landing */}
    <div className={styles.image}>
      <Img
        fluid={sources}
        alt=""
        style={{ height: "100vh" }}
        objectPosition={"47% 10%"}
        loading="eager"
      />
    </div>
    <div className={styles.content}>
      <a href="#contact">
        <button className={styles.button1}>Get in shape today</button>
      </a>
      <h1 className={styles.title}>
        Your <span>Best Body</span>
      </h1>
      <p className={styles.description}>
        Getting in shape isn't hard when you're in a supportive environment.
        Lifetime Fitness is more than just a gym - it's a caring family that's
        there to help you achieve your goals
      </p>
      <Link to="/blog">
        <button className={styles.button2}>View Our Blog</button>
      </Link>

      <div className={styles.footer}>
        <div className={styles.social}>
          <a
            href="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
            target="blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/lifetime.fitness.by.maduranga/"
            target="blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </div>
        <ul className={styles.highlights}>
          <li>OPEN LATE</li>
          <li>PROFESSIONAL INSTRUCTORS</li>
          <li>SUPPLEMENT BAR</li>
        </ul>
      </div>

      <div className={styles.mobile}>
        <div className={styles.headerCarousel}>
          <HeaderCarousel />
        </div>
        <Link to="/blog" className={styles.blog}>
          <button>View Our Blog</button>
        </Link>
        <div className={styles.mobileFooter}>
          <h2 className={styles.callToAction}>
            Get 1 <span>Free Trial</span> Workout
          </h2>
          <div className={styles.mobileSocial}>
            <a href="tel:+94777775576<">
              <FaPhone />
            </a>

            <a
              href="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>

            <a
              href="https://www.instagram.com/lifetime.fitness/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a href="mailto:info@lifetimefitness.lk" target="_top">
              <FaEnvelope />
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* Triangles */}
    <div className={styles.box1} />
    <div className={styles.box2} />

    {/* Clearfix */}
    <div className={styles.clearfix} />
  </div>
)

export default Landing
