import React from "react"

// Styles
import styles from "../styles/components/service.module.scss"

export default ({ icon, title, desc, last }) => (
  <div className={styles.service} style={last ? { marginBottom: 0 } : {}}>
    <div className={styles.imageWrapper}>
      <img src={icon} alt="icon" />
    </div>
    <div className={styles.text}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{desc}</p>
    </div>
  </div>
)
