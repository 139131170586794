import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaInfoCircle } from "react-icons/fa"
import Trainer from "../components/trainer"
import Title from "../components/title"

// Styles
import styles from "../styles/components/team.module.scss"

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      avishka: file(relativePath: { eq: "avishka-senevirathana.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={styles.main} id="team">
      <Title text="Meet Our Team" />

      <div className={styles.team}>
        <Trainer
          image={data.avishka.childImageSharp.fluid}
          name="Avishka Senevirathana"
          insta="https://www.instagram.com/lifetime.fitness.by.maduranga/"
          fb="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
        />
        {/* <Trainer
          image={data.supipi.childImageSharp.fluid}
          name="Supipi Harischandra"
          insta="https://www.instagram.com/lifetime.fitness.by.maduranga/"
          fb="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
        />
        <Trainer
          image={data.chamupa.childImageSharp.fluid}
          name="Chamupa Wickramarachchi"
          insta="https://www.instagram.com/lifetime.fitness.by.maduranga/"
          fb="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
        /> */}
      </div>
      <div className={styles.footer}>
        <FaInfoCircle />
        <p className={styles.text}>
          All our trainers are qualified instructors with a passion for fitness
        </p>
      </div>
    </div>
  )
}

export default Team
