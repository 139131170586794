import React from "react"
import Title from "./title"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

// Styles
import styles from "../styles/components/gallery.module.scss"
import { FaFacebookF, FaInstagram } from "react-icons/fa"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "gallery-lifetime-fitness-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1650, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "gallery-lifetime-fitness-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "gallery-lifetime-fitness-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "gallery-lifetime-fitness-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(relativePath: { eq: "gallery-lifetime-fitness-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6: file(relativePath: { eq: "gallery-lifetime-fitness-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={styles.main} id="gallery">
      <Title text="Image Gallery" />
      <div className={styles.grid}>
        <Zoom>
          <Img
            fluid={data.image1.childImageSharp.fluid}
            alt="image1"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt="image2"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt="image3"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image4.childImageSharp.fluid}
            alt="image4"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image5.childImageSharp.fluid}
            alt="image5"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image6.childImageSharp.fluid}
            alt="image6"
            style={{ width: "100%" }}
          />
        </Zoom>
      </div>
      <div className={styles.footer}>
        <p>Follow Us</p>
        <a
          href="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
          rel="nofollow"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/lifetime.fitness.by.maduranga/"
          rel="nofollow"
        >
          <FaInstagram />
        </a>
      </div>
    </div>
  )
}

export default Gallery
