import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { CarouselMain } from "./carousel"

// Styles
import styles from "../styles/components/about.module.scss"

const about = ({ image }) => (
  <div className={styles.main} id="about">
    <div className={styles.image}>
      <Img fluid={image} loading="eager" alt="" />
    </div>
    <div className={styles.bg}>
      <div className={styles.slider}>
        <CarouselMain />
      </div>
    </div>
    <div className={styles.mobile_slider}>
      <CarouselMain />
    </div>
  </div>
)

export default about
