import React, { Component } from "react"
import { navigate } from "gatsby-link"

// Styles
import styles from "../styles/components/contactForm.module.scss"

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class subscribe extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    emailValid: false,
  }

  handleChange = e => {
    if (e.target.name === "email") {
      this.validateEmail(e.target.value)
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  validateEmail = email => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.setState({
      emailValid: re.test(String(email).toLowerCase()),
    })
  }

  render() {
    return (
      <div className={styles.main}>
        <form
          className={styles.form}
          name="contact"
          method="post"
          action="/success/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <label style={{ display: "none" }}>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
          <input
            type="text"
            name="name"
            placeholder="Name"
            required
            onChange={this.handleChange}
            value={this.state.name}
          />
          <input
            type="email"
            name="email"
            required
            placeholder="Email"
            onChange={this.handleChange}
            value={this.state.email}
          />
          <textarea
            name="message"
            placeholder="Message"
            required
            onChange={this.handleChange}
            value={this.state.message}
          />
          <button
            className={styles.btn}
            type="submit"
            disabled={
              !this.state.name ||
              !this.state.email ||
              !this.state.message ||
              !this.state.emailValid
            }
            style={
              !this.state.name ||
              !this.state.email ||
              !this.state.message ||
              !this.state.emailValid
                ? {
                    backgroundColor: "#484e51",
                    cursor: "default",
                    transform: "none",
                  }
                : { backgroundColor: "#fe5153" }
            }
          >
            Send
          </button>
        </form>
      </div>
    )
  }
}

export default subscribe
