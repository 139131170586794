import React from "react"
import { FaPhone, FaEnvelope, FaLocationArrow } from "react-icons/fa"
// Styles
import styles from "../styles/components/contact.module.scss"

export default () => (
  <>
    <div>
      <div className={styles.info}>
        <FaPhone />
        <p>+94 777 775 576</p>
      </div>
      <div className={styles.info}>
        <FaEnvelope />
        <p>info@lifetimefitness.lk</p>
      </div>
    </div>
    <div className={styles.address}>
      <div className={styles.info}>
        <FaLocationArrow />
        <p>709/6 B, Pannipitiya Rd, Sri Jayawardenepura Kotte</p>
      </div>
    </div>
  </>
)
