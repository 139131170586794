import React from "react"

// Styles
import styles from '../styles/components/rating.module.scss'

const Rating = props => {
  const getRating = rating => {
    const percentage = (rating / 5) * 100
    const percentageRounded = `${Math.round(percentage / 10) * 10}%`
    // Set width of stars inner to percentage

    return percentageRounded
  }
  return (
    <div className={styles.starWrapper}>
      <div className={styles.starsOuter} id="stars-outer">
        <div
          className={styles.starsInner}
          id="stars-inner"
          style={{ width: getRating(props.rating) }}
        />
      </div>
    </div>
  )
}

export default Rating
