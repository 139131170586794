import React, { Component } from "react"
import { navigate } from "gatsby-link"
import ReactStars from "react-stars"

// Styles
import styles from "../styles/components/leaveReview.module.scss"

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class subscribe extends Component {
  state = {
    name: "",
    email: "",
    review: "",
    emailValid: false,
    rating: 5,
  }

  handleChange = e => {
    if (e.target.name === "email") {
      this.validateEmail(e.target.value)
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "review",
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  validateEmail = email => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.setState({
      emailValid: re.test(String(email).toLowerCase()),
    })
  }
  ratingChanged = rating => {
    this.setState({ rating: rating })
  }

  render() {
    return (
      <div className={styles.main}>
        <form
          className={styles.form}
          name="review"
          method="post"
          action="/thank-you/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <h1 className={styles.title}>Write A Review</h1>
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="review" />
          <label style={{ display: "none" }}>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
          <input
            type="number"
            name="rating"
            required
            placeholder="Rating"
            value={this.state.rating}
            style={{ display: "none" }}
          />

          <ReactStars
            count={5}
            onChange={this.ratingChanged}
            size={24}
            color2={"#ffd700"}
            value={this.state.rating}
            half={false}
            className={styles.starRating}
          />
          <input
            type="text"
            name="name"
            placeholder="Name"
            required
            onChange={this.handleChange}
            value={this.state.name}
          />
          <input
            type="email"
            name="email"
            required
            placeholder="Email"
            onChange={this.handleChange}
            value={this.state.email}
          />

          <textarea
            name="review"
            placeholder="Review"
            required
            onChange={this.handleChange}
            value={this.state.review}
          />
          <button className={styles.btn} type="submit">
            Send
          </button>
        </form>
      </div>
    )
  }
}

export default subscribe
