export const reviews = [
  {
    name: "Amila Prabhath",
    rating: 4,
    review:
      "Fitness is a huge thing in everybody's life. and everyone has to stay healthy. if you are looking to stay fit and healthy, this is the right place for you. I hit this gym daily at 5 in the morning and the trainer and instruments are just great. you can actually see your body coming into shape. And the trainer can help you get any kind of body you want.",
  },
  {
    name: "Dinith Athukorala",
    rating: 5,
    review:
      "Amazing staff and support. Very good crowd. Superb environment. Open almost 24*7",
  },
  {
    name: "Chithmi Ranawaka",
    rating: 5,
    review:
      "One of the best fitness place I have ever been. Best place with amazing trainers.. Surportive, Friendly and knowledgeable guides ... Hoping to workout more with you all❤️  ️💪",
  },
  {
    name: "Esala Sumanasena",
    rating: 5,
    review:
      "Best place to go if you consider your fitness. The owner is well certified gym instructor. Custom made fitness schedule will be given for each member. Diet tips also given in order to achieve your fitness goal. Very friendly support instructors available including a lady fitness instructor. They will guide you and show you how to do technically correct work outs. There are lot of gym machines available. Car park also available in the rear. Fitness centre is open for 24 hrs (week days) as well.",
  },
  {
    name: "Shafraz Asnavi",
    rating: 5,
    review:
      "A fitness club with a vision, as I would like to call it. Maduranga, Fitness Chief, ensures that his clients are well trimmed and ready to lead a healthy life. Got a cheerful and helpful team to support on all aspects of fitness with thorough knowledge on the subject. Wanna look good, this is the place to be! Lets power up!",
  },
  {
    name: "K Chinthaka",
    rating: 4,
    review:
      "Used this gym on a day membership (500 Rupees). staff are great. Helped me figuring out the treadmill. Even gave directions to the gym from my home. Gym has A/C and a good vibe. The only minor issue is that treadmills have a time limit of 20 minutes. Although, I can understand the need for it, especially during peak times.",
  },
  {
    name: "Thanura Wanniatchi",
    rating: 5,
    review:
      "Good physical fitness center. Knowledgeable, focused and friendly instructors, accommodating management, practical facilities for any busy person.",
  },
  {
    name: "Eshana Gabadamudalige",
    rating: 5,
    review: "A homely gym with all the best features, and friendly staff!",
  },
  {
    name: "Kaveenga Wijayasekara",
    rating: 4,
    review:
      "Maduranga and his team make sure that this gym is always super clean, and look after their clients very well.",
  },
  {
    name: "Vinura Gunasekara",
    rating: 5,
    review:
      "Now open 24 Hours on all 5 weekdays 💪   The best gym in town with great service and good equipments. I personally suggest anyone who takes fitness seriously to make come join lifetime fitness - Thalawathugoda.",
  },
  {
    name: "Siyam Moh",
    rating: 5,
    review: "Amazing place to get get what you want. Simply clarified",
  },
  {
    name: "Thivantha Harischandra",
    rating: 5,
    review:
      "This is a 24/7 Gym now for weekdays(Mon-Fri). A great place for fitness stuff. They have lot of machines. Personal trainers are kind and supportive. Membership fee is also more than fair. Card payments available too. You can have workout plan, diet plan and training without paying extra money for them. Trainers always ready to support us. Even though this is not a big gym, service they provide is remarkable. On Sundays they start 8 am until 6 pm. Poya days are off days. Parking lot available here. No wheelchair accessible entrance or wheelchair accessible parking available here.",
  },
  {
    name: "Ayantha Munaweera",
    rating: 5,
    review: "You always get individual attention. Best gym I've ever been to",
  },
  {
    name: "Ashan Rathnawardhana",
    rating: 5,
    review:
      "Friendly and super helpful owner/ trainer. You get everything you need to workout in a small space. Friendly staff who genuinely care about your workout goals. Nice juice bar. Air conditioned. Affordable too. Why go to pretentious expensive places when you can come here?",
  },
  {
    name: "Chaanaka T Paranavithana",
    rating: 5,
    review:
      "Best place to get your body in shape. This place might not be the biggest or the best place in Sri Lanka but it sure is the best place in this area . Super friendly and helpful staff . The member ships are very reasonable . It has all the items which you need . It has separate section for everything . Separate section for cardio , starching , weight lifting . It has a juice bar , supplement bar and mini shop to purchase towels and other small items . They give u a mean plan and a workout schedule for free . They take you to your limits and make sure u achieve your goals . The wash rooms are very clean too. U can take good shower after the workout to freshen up .",
  },
  {
    name: "Shashika Maduranga",
    rating: 5,
    review:
      "It has only been 5 months since I joined Lifetime Fitness and I'm already in love with the place. The instructors are well skilled and also very friendly. They guided me through every step of my routine. Simply, if you want a difference in your physique this is BEST place. #LifetimeFitness",
  },
  {
    name: "Lakitha Ramanayake",
    rating: 5,
    review:
      "First things first: superb atmosphere hats off for that. Kudos to the team who is doing an awesome job. I would recommend Lifetime Fitness to anyone whom would want to FOCUS.GAIN.ATTAIN 😁",
  },
  {
    name: "Dilan Sandaruwan",
    rating: 5,
    review:
      "Best service out of all the gyms i went.. really worth for the price you pay. They are doing it as a passion..",
  },
  {
    name: "Charith Saranga",
    rating: 5,
    review:
      "Lifetime fitness has changed my lifestyle.. When you thrive to reach your fitness goals, you have to have a fitness professional beside you.. I'm always confident as my gym is with me anytime when needed.. I'm grateful for their dedication to make people healthy..  Big shout-out for Lifetime fitness..",
  },
  {
    name: "Hashan Madushanka Jayakody",
    rating: 5,
    review:
      "Best gym I have ever been. Actually my second home. They have professional qualified team. Gym is very clean and the best place to workout and achieve fitness goals",
  },
  {
    name: "Yashasvi Athugala",
    rating: 5,
    review:
      "Best gym ever.. very good service and Instructors are always there to help, unlike other gyms. Very friendly environment for anyone. i 100% recommend this gym to everyone.",
  },
  {
    name: "Ranuli Weerabahu",
    rating: 5,
    review:
      "Out of the gyms in our area , this can be mentioned as THE BEST .Visit the gym and find out for yourself. You'll be amazed. Give them three months and you'll have the body you always dreamed of .... 💪      💪  ",
  },
  {
    name: "Rayharn Randave",
    rating: 5,
    review:
      "People cannot be measured by size.... as like that this gym has changed a lot of peoples lives including mine , as in Mr Maduranga ,the owner of the gym and  as the main trainer has gone beyond money to help people out .. with on to on motivation... i have trained before in a gym, but in Lifetime fitness its just home for me   ... with the crew that i feel as my own  family .........  -Thank You-  -Best Regards-",
  },
  {
    name: "Kushan Kavirathne",
    rating: 5,
    review:
      "Gym is very grate. Facilities are good, staff are friendly and place is clean. This is a grate place to be you can get your mind off of everything and just enjoy by yourself with frinds and make your fitness goals.",
  },
]
