import React from "react"
import Title from "./title"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

// Styles
import styles from "../styles/components/gallery.module.scss"

const Transformations = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "transformations/0.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1650, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "transformations/1.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "transformations/2.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "transformations/3.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(relativePath: { eq: "transformations/4.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6: file(relativePath: { eq: "transformations/5.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image7: file(relativePath: { eq: "transformations/6.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image8: file(relativePath: { eq: "transformations/7.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image9: file(relativePath: { eq: "transformations/8.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image10: file(relativePath: { eq: "transformations/9.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={styles.main} id="gallery">
      <Title text="Body Transformations" />
      <div className={styles.grid}>
        <Zoom>
          <Img
            fluid={data.image1.childImageSharp.fluid}
            alt="image1"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt="image2"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt="image3"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image4.childImageSharp.fluid}
            alt="image4"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image5.childImageSharp.fluid}
            alt="image5"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image6.childImageSharp.fluid}
            alt="image6"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image7.childImageSharp.fluid}
            alt="image7"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image8.childImageSharp.fluid}
            alt="image6"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image9.childImageSharp.fluid}
            alt="image9"
            style={{ width: "100%" }}
          />
        </Zoom>
        <Zoom>
          <Img
            fluid={data.image10.childImageSharp.fluid}
            alt="image10"
            style={{ width: "100%" }}
          />
        </Zoom>
      </div>

    </div>
  )
}

export default Transformations
